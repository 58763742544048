var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "xn-guide",
      on: {
        touchmove: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("img", {
        staticStyle: {
          "margin-left": "calc(calc(100% - 84px) / 2)",
          "margin-top": "30%",
        },
        attrs: { src: require("./img/downlogo.png"), alt: "" },
      }),
      _c(
        "h2",
        { staticStyle: { "text-align": "center", "margin-top": "3%" } },
        [_vm._v(" 智慧停车管理端 ")]
      ),
      _c("img", {
        staticStyle: {
          "margin-left": "calc(calc(100% - 108px) / 2)",
          "margin-top": "30%",
        },
        attrs: { src: require("./img/android.png"), alt: "" },
        on: { click: _vm.appDlios },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.wxTip,
              expression: "wxTip",
            },
          ],
          attrs: { id: "weixin-tip" },
          on: { click: _vm.closeTip },
        },
        [_vm._m(0)]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("img", {
        attrs: {
          width: "50%",
          src: require("./img/live_weixin.png"),
          alt: "微信打开",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }